export default [
    {
        part: "CSC-LMS and Courses",
        items: [
            {
                title: 'What is CSC-LMS?',
                text: '<p>Conceived from the thrust of the National Government for digital transformation through digitalized processes, the CSC LMS serves as the official platform of the Civil Service Commission as part of its mandates to continuously capacitate the government workforce by making the Learning and Development initiatives of the commission become more accessible by its clients across the nation through the Civil Service Institute.</p>'
            },
            {
                title: 'Is the CSC-LMS secured?',
                text: '<p>Yes. Our LMS underwent series of Vulnerability Assessment (VA) and Penetration Testing (PT) conducted by the Department of Information and Communications Technology (DICT) to ensure that the system is safe from cybersecurity vulnerability issues. It also underwent Privacy Impact Assessment (PIA) through the help of the National Privacy Commission (NPC) to ensure that risks associated with privacy issues are addressed prior its launching and that the collection of information from end-users are compliant to RA 10173 otherwise known as the Privacy Act of 2012.</p>'                
            },
            {
                title: 'What are the types of courses you offer?',
                text: '<p>As of date, our courses are subdivided into three categories namely Leadership Development Courses, Foundational Courses, and Human Resource Management Courses. For specific course titles, you may browse our available course through <a href="https://elearning.csc.gov.ph" target="_blank">https://elearning.csc.gov.ph</a></p>'                
            },
            {
                title: 'How do I know the investment cost for each training course?',
                text: '<p>The charge for each training course varies. To find out the specific investment cost for each of our courses, feel free to browse our available courses to see exact amount of the investment costs as well as other relevant information such as the course descriptions and topic outlines.</p>'
            },
            {
                title: 'What is the mode of teaching?',
                text: '<p>As of date, our courses can be accessed via online. Specifically, the mode of delivery is divided into two types namely: Online – Instructor Led, and Online – Self Paced.</p>'
            },
            {
                title: 'What is the main difference between Online – Instructor Led and Online – Self-Paced Course?',
                text: '<p>Online-Instructor Led Courses are those which are being delivered via virtual meeting platforms such as Zoom, MS Teams, and/or Google Meet. Subject Matter Experts (SME) and the learners may interact through various virtual features embedded in the platforms such as camera and microphone for them to see and converse real-time virtually among others. Online-Self-Paced on the other hand is a mode of delivery by which the learner works on the specific course on his own terms. There is no real-time interaction between the instructor and the learner. Learning journey on self-paced courses are supplemented with videos and/or reading materials. In this mode of delivery, learners track their own learning progress on the course.</p>'
            },
            {
                title: 'How long will it take me to finish self-paced courses?',
                text: '<p>Our self-paced courses can be accomplished within your own terms and convenience provided that you finish them within the specified timeline as reflected in the <span class="l-primary--text">course description</span>. On the average, you can finish the self-paced courses within <span class="l-primary--text">three (3) to seven (7) days</span>.<p>'
            },
            {
                title: 'Are all your courses in the LMS accorded with Certificate of Completion (COC)?',
                text: '<p>Yes. Learners who complete the courses and were able to fully satisfy the corresponding requirements such as synchronous and asynchronous activities will receive certificate of completion.</p>'
            },
            {
                title: 'Are all your courses in the LMS available throughout the year?',
                text: '<p>Unfortunately, our courses in the LMS are being run on a specific month/s within each year. Therefore, the availability of each course depends on the date to which they are scheduled to be conducted.</p>'
            },
            {
                title: 'Will I get notifications or updates for the courses that will be conducted within a given period?',
                text: '<p>As an end-user, you may visit our LMS from time to time to check for the available courses or check for our announcements on our Official Facebook Page: The Civil Service Institute – CSC.</p>'
            },
        ]
    },
    {
        part: 'Accessibility',
        items: [
            {
                title: 'Is the CSC-LMS accessible in any device?',
                text: '<p>Yes. The CSC-LMS is accessible through desktop, laptops, tablets, and mobile devises that support web browsers and wireless internet connections or cellular data.</p>'
            },
            {
                title: 'Does the CSC-LMS works even without internet?',
                text: '<p>As of the date, the CSC-LMS can only be accessed through various devices provided the device is connect to internet or cellular data. But this feature of the LMS being operational even if offline will be included in its future development.</p>'
            },
            {
                title: 'How do I access the CSC-LMS through other devices?',
                text: '<p>To access the CSC-LMS using other device like mobile phones or tablets, kindly click this link: <a href="https://elearning.csc.gov.ph/" target="_blank">https://elearning.csc.gov.ph/</a>'
            },
        ]
    }
]